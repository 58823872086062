import { render, staticRenderFns } from "./PasswordResetInitialPage.vue?vue&type=template&id=da3d623c&scoped=true"
import script from "./PasswordResetInitialPage.vue?vue&type=script&lang=js"
export * from "./PasswordResetInitialPage.vue?vue&type=script&lang=js"
import style0 from "./PasswordResetInitialPage.vue?vue&type=style&index=0&id=da3d623c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da3d623c",
  null
  
)

export default component.exports