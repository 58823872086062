<template>
  <v-app>
    <div class="main-wrapper">
      <v-card
        class="my-base-vcard-style"
        elevation="0"
      >
        <v-col
          cols="12"
          sm="12"
          md="5"
          lg="5"
        >
          <v-row
            align="center"
            class="pl-3"
          >
            <img
              :src="logo"
              width="100px"
            >
          </v-row><br>
          <h2 class="title-style">
            Forgot Password ?
          </h2>
          <p>
            Enter the email associated with your account and we'll send an email with instructions to reset your password.
          </p>
          <v-card
            class="my-vcard-style"
            elevation="0"
          >
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="email"
                  hint="Enter your registered mail id here."
                  label="Email*"
                  :rules="emailRules"
                  outlined
                  required
                  dense
                />
              </v-form>
            </v-card-text>
          </v-card>
          <v-card-actions class="btn-container">
            <v-btn
              class="mt-2"
              color="#000"
              @click="resetPassword()"
            >
              Reset Password
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              @click="back()"
            >
              Back
            </v-btn>
          </v-card-actions>
        </v-col>
      </v-card>
    </div>
    <dashboard-core-footer />
    <centre-spinner
      :loading="loading"
    />
  </v-app>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';
  import { cdnUrl } from 'src/app-globals';

  export default {
    name: 'PasswordResetInitialPage',
    components: {
      'centre-spinner': spinner,
      DashboardCoreFooter: () => import('../dashboard/components/core/Footer'),
    },
    data () {
      return {
        email: '',
        valid: false,
        loading: false,
        emailRules: [
          (v) => !!v || 'E-mail is required',
          (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
        ],
        logo: `${cdnUrl}/website/NewDBSLogo.png`,
      };
    },
    methods: {
      resetPassword () {
        if (this.$refs.form.validate() === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'Please enter the registered email address before proceeding',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
          return;
        }
        this.loading = true;
        this.$store.dispatch('passwordreset/resetPassword', {
          email: this.email,
        }).then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'If your email is valid, you will receive an email with instructions on how to reset your password.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.$router.push({ name: 'Login' });
        }).catch(error => {
          this.loading = false;
          this.$store.dispatch('alert/onAlert', {
            message: error.response.data.message ? error.response.data.message : 'Please enter a valid email address!',
            type: Constants.ALERT_TYPE_ERROR,
          }, { root: true });
        })
        .finally(
          this.loading = false,
        );
      },
      back () {
        this.$router.push({ name: 'Login' });
      },
    },
  };
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
*{
  font-family: "DM Sans", serif !important;
}
.my-style {
  text-align: center;
  margin-top: 25px;
  margin-bottom: 50px;
}
.title-style {
  color: #000;
  text-align: left;
  font-size: 35px;
}
.icon-style {
  color: #000;
  font-size: 45px;
}
.v-card .v-card__actions {
  display: flex!important;
  flex-direction: column!important;
  padding-right: 0px;
}
.v-btn{
  width: -webkit-fill-available!important;

}
p{
  text-align: left;
  margin-bottom:0px!important;
}
.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
  margin-left: 0px !important;
  margin-right: 11px;
}
.my-base-vcard-style{
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
}
.v-card__text[data-v-23479bf4]{
  padding-left:0px!important;
  padding-top:0px!important;
  padding-bottom: 0px!important;
}
.v-card .v-card__actions[data-v-23479bf4]{
  padding-left: 0px!important;
  padding-right: 0px!important;
  padding-top: 0px!important;
}
.main-wrapper{
  height:100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.v-card{
  width:100%;
}
.v-text-field >>> .v-text-field__details{
  margin-bottom: -13px !important;
}
</style>
